
import { Vue, Component } from 'vue-property-decorator';
import {KarsilastirmaliDataTableEntity} from "@/entity/Raporlama/KarsilastirmaliDataTableEntity";

@Component
export default class KarsilastirmaDataTableAy extends Vue {
  search: string = '';


  headers = [
    { text: 'Tarih', align: 'start', sortable: false, value: 'hafta', class:'header-style' },
    { text: 'Toplam Tahsilat', value: 'toplamTahsilat', class:'header-style' },
    { text: 'Tahsilat Oranı', value: 'tahsilatOrani', class:'header-style' },
    { text: 'Derdest K. Tahsilat', value: 'derdesttoplam', class:'header-style' },
    { text: 'Hitam Tahsilat', value: 'hitamtoplam', class:'header-style' },
  ];
  sutunlar: string[] = this.headers.map(header => header.value);

  items : Array<KarsilastirmaliDataTableEntity> =[]

  mounted(){
    this.fetchData()
  }
  async fetchData(){
    try{
      const response = await this.$http.post('/api/v1/karsilastirmali-tahsilat-raporlama',{
        "userId" : this.$store.state.user.id,
        "basTar" : "2020-01-01",
        "bitTar" : "2024-01-01",
        "interval" : "monthly"
      });

      const data = response.data
      this.items = data.map((karsilastirmaDataTableItems:any) =>{
        return karsilastirmaDataTableItems
      })
    }catch (error) {
      console.error('hata:', error);
    }
  }

  get seciliHeader() {
    return this.headers.filter((header) => this.sutunlar.includes(header.value));
  }
}
