
import {Vue, Component, Prop, Watch, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({})
export default class KarsilastirmaliGrafikColChart extends Mixins(SimpleInputMixin) {
  @Prop() selectedMuvId: number | null | undefined;
  @Prop() selectedKurumId: number | null | undefined;
  @Prop()series!:Array<any>

  tahsilatOraniTitleChart = ["Toplam Tahsilat", "Tahsilat Oranı", "Derdest Kısmi Toplam", "Hitam Tahsilat"]


  options = {
    series: this.series,
    chart: {
      type: 'bar',
      offsetY: 40,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#5D7285", "#5FB3F6"]
    },
    xaxis: {
      categories: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
      offsetY: 5,
    },
    yaxis: {
      title: {
        text: '₺ (bin)'
      }
    },
    fill: {
      opacity: 1,
      colors: ["#5D7285", "#5FB3F6"]
    },
    tooltip: {
      y: {
        formatter: function (val: string) {
          return "₺ " + val
        }
      }
    }
  }

  /*mounted(){
    this.selectedOption = 'Toplam Tahsilat';
    this.fetchData()
      .then(() => {
        this.toplamTahsilat();
      })
  }

  async fetchData(){
    try{
      const response:any  = await this.$http.post('/api/v1/karsilastirmali-tahsilat-raporlama',{
        "birinci_yil" : "2023",
        "ikinci_yil" : "2024",
        "muvekkil_id": [1,2],
        "kurum_id": [1],
      });



      const data = response.tarihli_toplamlar


      for (let i = 0; i < data.length; i++) {
        this.chartData.push(data[i])
      }
    }catch (error) {
      this.loading = true;

      console.error('hata:', error);
    }
  }

  @Watch('selectedOption')
  selectOption(item){
    if(item === 'Toplam Tahsilat'){
      this.toplamTahsilat()
    }
    if (item === 'Derdest Kısmi Toplam'){
      this.derdestToplam()
    }
    if (item === 'Hitam Tahsilat'){
      this.hitamToplam()
    }
    if (item === 'Tahsilat Oranı'){
      this.tahsilatOrani()
    }
  }

  derdestToplam(){
    this.options.series = [
      {
        name: 'Geçen Yıl',
        data: [0,0,0,0,0,0,0,0,...this.chartData,],
        color: '#5D7285',
      },
      {
        name: 'Bu Yıl',
        data: [0,0,0,0,0,0,0,0,...this.chartData.slice().reverse()],
        color: '#5FB3F6',
      },
    ]
  }

  hitamToplam(){
    this.options.series = [
      {
        name: 'Geçen Yıl',
        data: [0,0,0,0,0,0,0,0,...this.chartDataHitam,],
        color: '#5D7285',
      },
      {
        name: 'Bu Yıl',
        data: [0,0,0,0,0,0,0,0,...this.chartDataHitam.slice().reverse()],
        color: '#5FB3F6',
      },
    ]
  }

  toplamTahsilat(){
    const seriesDataThisYear = Array(12).fill(0);

    for (const tahsilat of this.chartData) {
      const monthIndex = new Date(tahsilat.tarih).getMonth();
      seriesDataThisYear[monthIndex] += Number(tahsilat.toplam_tutar);
    }

    this.options.series =[
      {
        name: 'Geçen Yıl',
        data: [],
        color: '#5D7285',
      },
      {
        name: 'Bu Yıl',
        data: [],
        color: '#5FB3F6',
      },
    ]
  }

  tahsilatOrani(){
    let tahsilatToplamiIlkAy = Number( this.chartData[0]) + Number(this.chartDataHitam[0]);
    let tahsilatToplamiIkinciAy = Number( this.chartData[1]) + Number(this.chartDataHitam[1]);
    let tahsilatToplamiUcuncuAy = Number( this.chartData[2]) + Number(this.chartDataHitam[2])
    let tahsilatToplamiDorduncuAy =  Number( this.chartData[3]) + Number(this.chartDataHitam[3])

    let tahsilatOraniIlkAy = Number((this.chartDataHitam[0])/tahsilatToplamiIlkAy).toFixed(2);
    let tahsilatOraniIkinciAy = Number((this.chartDataHitam[1])/tahsilatToplamiIkinciAy).toFixed(2);
    let tahsilatOraniUcuncuAy = Number((this.chartDataHitam[2])/tahsilatToplamiUcuncuAy).toFixed(2);
    let tahsilatOraniDorduncuAy =  Number((this.chartDataHitam[3])/tahsilatToplamiDorduncuAy).toFixed(2);

    this.options.series =[
      {
        name: 'Geçen Yıl',
        data: [0,0,0,0,0,0,0,0,tahsilatOraniIlkAy, tahsilatOraniIkinciAy, tahsilatOraniUcuncuAy, tahsilatOraniDorduncuAy],
        color: '#5D7285',
      },
      {
        name: 'Bu Yıl',
        data: [0,0,0,0,0,0,0,0,tahsilatOraniDorduncuAy, tahsilatOraniUcuncuAy, tahsilatOraniIkinciAy, tahsilatOraniIlkAy],
        color: '#5FB3F6',
      },
    ]
  }*/




}
