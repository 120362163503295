
import GenelIstatistikPerformansGrafik
  from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikPerformansGrafik";
import MuvekkilTableInfo from "@/components/infos/raporlama-ofispro/MuvekkilTableInfo";
import UstBasliklar from "@/components/infos/raporlama-ofispro/helper-comps/UstBasliklar";
import TahsilatlarChart from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarChart";
import TahsilatlarDataTable from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarDataTable";
import Dates from "@/components/inputs/Dates";
import {Component, Vue} from "vue-property-decorator";
import GenelIstatistikGrafikInfo from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikGrafikInfo";
import OzelMenuRaporlama from "@/components/infos/raporlama-ofispro/helper-comps/OzelMenuRaporlama";
import KarsilastirmaliGrafikChart from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaliGrafikChart";
import KarsilastirmaDataTable from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaDataTable";
import KarsilastirmaliGrafikColChart
  from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaliGrafikColChart";
import OzelAralikDatePicker from "@/components/infos/raporlama-ofispro/helper-comps/OzelAralikDatePicker.vue";
import KarsilastirmaliGrafikColAyChart
  from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaliGrafikColAyChart.vue";
import KarsilastirmaliGrafikColHaftaChart
  from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaliGrafikColHaftaChart.vue";
import KarsilastirmaDataTableAy from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaDataTableAy.vue";
import KarsilastirmaDataTableHafta
  from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaDataTableHafta.vue";
import MuvekkilSecim from "@/components/infos/raporlama-ofispro/helper-comps/MuvekkilSecim.vue";
import KarsilastirmaliYilSecim from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaliYilSecim.vue";
import TahsilatlarHaftaChart from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarHaftaChart.vue";
import TahsilatlarAyChart from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarAyChart.vue";
@Component({
  components: {
    TahsilatlarAyChart,
    TahsilatlarHaftaChart,
    KarsilastirmaliYilSecim,
    MuvekkilSecim,
    KarsilastirmaDataTableHafta,
    KarsilastirmaDataTableAy,
    KarsilastirmaliGrafikColHaftaChart,
    KarsilastirmaliGrafikColAyChart,
    OzelAralikDatePicker,
    KarsilastirmaliGrafikColChart,
    KarsilastirmaDataTable,
    KarsilastirmaliGrafikChart,
    OzelMenuRaporlama,
    UstBasliklar, MuvekkilTableInfo, GenelIstatistikPerformansGrafik, TahsilatlarChart, TahsilatlarDataTable, Dates,GenelIstatistikGrafikInfo}
})
export default class KarsilastirmaliRaporlamaView extends Vue {
  selectedItem= null
  loading: boolean = false;
  items=['2010','2011','2012','2013','2014','2015','2016','2017','2018','2019','2020','2021','2022','2023','2024']

  baslangicTarihi:string = ''
  bitisTarihi:string = ''

  muvAndKurumIsim:string = ''

  selectedBaslangicTarihi:string = '2023'
  selectedBitisTrihi :string= '2024'

  karsilastirmaliGrafikData : any = []
  selectedOption: any = []

  selectedMuvId: Array<number | null> = [null];
  selectedKurumId: Array<number | null> = [null]

  allData:any = []

  cardsDosya: {
    id: number;
    title: string;
    yil:string;
    number: string|number;
    yil2: string;
    number2: string|number;
  }[] = [];

  cardsGenelToplam: {
    id: number;
    title: string;
    yil:string;
    number: string|number;
    yil2: string;
    number2: string|number;
  }[] = [];

  cardsTahsilat: {
    id: number;
    title: string;
    yil:string;
    number: string|number;
    yil2: string;
    number2: string|number;
  }[] = [];


  handleSelectedOption(selectedOption){
    this.selectedOption = selectedOption

    if (this.baslangicTarihi && this.bitisTarihi){
      if (selectedOption==='Toplam Tahsilat'){
        this.karsilastirmaliGrafikData=[
          {
            name: `${this.baslangicTarihi}`,
            data: this.allData[this.baslangicTarihi]['tahsilat_toplam_tutar'],
          },
          {
            name: `${this.bitisTarihi}`,
            data: this.allData[this.bitisTarihi]['tahsilat_toplam_tutar'],
          },
        ]
      }
    }
  }

  handleMuvAndKurumIsim(name:string){
    this.muvAndKurumIsim = name
  }


  handleMuvAndKurumIds(muvId, kurumId) {
    this.selectedMuvId = muvId;
    this.selectedKurumId = kurumId
    this.fetchData(this.selectedBaslangicTarihi,this.selectedBitisTrihi)
  }

  handleSelectedMuvAndKurumIds(muvId, kurumId) {
    this.selectedMuvId = []
    this.selectedKurumId = []
    this.selectedMuvId = muvId;
    this.selectedKurumId = kurumId
    this.fetchData(this.selectedBaslangicTarihi,this.selectedBitisTrihi)
  }

  handleBaslangicTarihi(baslangic){
    this.baslangicTarihi = baslangic
    this.selectedBaslangicTarihi = baslangic

  }

  handleBitisTarihi(bitis){
    this.selectedBitisTrihi = bitis
    this.bitisTarihi = bitis
    this.fetchData(this.selectedBaslangicTarihi,this.selectedBitisTrihi)

  }

  mounted(){
    this.selectedOption = 'Toplam Tahsilat';
  }

  async fetchData( basTar:any, bitTar:any){
    try{
      let startDate = basTar || "";
      let endDate = bitTar || "";


      const response:any = await this.$http.post('/api/v1/karsilastirmali-tahsilat-raporlama',{
        "birinci_yil" : startDate,
        "ikinci_yil" : endDate,
        "muvekkil_id": this.selectedMuvId,
        "kurum_id": this.selectedKurumId,
      });
      this.baslangicTarihi = startDate
      this.bitisTarihi = endDate

      this.allData.push(response)

      this.cardsDosya= [
        {
          id: 1,
          title: 'Toplam Dosya Sayısı',
          yil:startDate? startDate : 'Yıl Seçiniz',
          number: response[startDate]['toplam_takip_sayisi'] + ' Adet' || 0 + 'Adet',
          yil2: endDate || 'Yıl Seçiniz',
          number2: response[endDate]['toplam_takip_sayisi'] + ' Adet' || 0 + 'Adet',
        },
        {
          id: 2,
          title: 'Açılmamış Dosya Sayısı',
          yil:startDate || 'Yıl Seçiniz',
          number: response[startDate]['acilmamis_takip_sayisi'] + ' Adet' || 0 + 'Adet',
          yil2: endDate || 'Yıl Seçiniz',
          number2: response[endDate]['acilmamis_takip_sayisi'] + ' Adet' || 0 + 'Adet',
        },
        {
          id: 3,
          title: 'Derdest Dosya Sayısı',
          yil:startDate || 'Yıl Seçiniz',
          number: response[startDate]['acik_takip_sayisi'] + ' Adet' || 0 + 'Adet',
          yil2: endDate || 'Yıl Seçiniz',
          number2: response[endDate]['acik_takip_sayisi'] + ' Adet' || 0 + 'Adet',
        },
        {
          id: 4,
          title: 'Hitam Dosya Sayısı',
          yil:startDate || 'Yıl Seçiniz',
          number: response[startDate]['kapali_takip_sayisi'] + ' Adet' || 0 + 'Adet',
          yil2: startDate || 'Yıl Seçiniz',
          number2: response[endDate]['kapali_takip_sayisi'] + ' Adet' || 0 + 'Adet',
        },
      ]

      this.cardsGenelToplam= [
        {
          id: 1,
          title: 'Genel Toplam Tutarı',
          yil:startDate || 'Yıl Seçiniz',
          number: this.$helper.tutarStrIstatistik(response[startDate]['toplam_takip_toplam_tutar']) || '0 ₺',
          yil2: endDate || 'Yıl Seçiniz',
          number2: this.$helper.tutarStrIstatistik(response[endDate]['toplam_takip_toplam_tutar']) || '0 ₺',
        },
        {
          id: 2,
          title: 'Açılmamış Toplam Tutar',
          yil:startDate || 'Yıl Seçiniz',
          number: this.$helper.tutarStrIstatistik(response[startDate]['acilmamis_takip_toplam_tutar']) || '0 ₺',
          yil2: endDate || 'Yıl Seçiniz',
          number2: this.$helper.tutarStrIstatistik(response[endDate]['acilmamis_takip_toplam_tutar'])  || '0 ₺',
        },
        {
          id: 3,
          title: 'Derdest Toplam Tutarı',
          yil:startDate || 'Yıl Seçiniz',
          number: this.$helper.tutarStrIstatistik(response[startDate]['acik_takip_toplam_tutar']) || '0 ₺',
          yil2: endDate || 'Yıl Seçiniz',
          number2: this.$helper.tutarStrIstatistik(response[endDate]['acik_takip_toplam_tutar']) || '0 ₺',
        },
        {
          id: 4,
          title: 'Hitam Toplam Tutarı',
          yil:startDate || 'Yıl Seçiniz',
          number: this.$helper.tutarStrIstatistik(response[startDate]['kapali_takip_toplam_tutar']) || '0 ₺',
          yil2: endDate || 'Yıl Seçiniz',
          number2: this.$helper.tutarStrIstatistik(response[endDate]['kapali_takip_toplam_tutar']) || '0 ₺',
        },
      ]

      this.cardsTahsilat= [
        {
          id: 1,
          title: 'Toplam Tahsilat Tutarı',
          yil:startDate || 'Yıl Seçiniz',
          number: this.$helper.tutarStrIstatistik(response[startDate]['tahsilat_toplam_tutar']) || '0 ₺',
          yil2: endDate || 'Yıl Seçiniz',
          number2: this.$helper.tutarStrIstatistik(response[endDate]['tahsilat_toplam_tutar']) || '0 ₺',
        },
        {
          id: 2,
          title: 'Açılmamış Tahsilat Tutarı',
          yil:startDate || 'Yıl Seçiniz',
          number: this.$helper.tutarStrIstatistik(response[startDate]['acilmamis_takip_tahsilat_toplam_tutar']) || '0 ₺',
          yil2: endDate || 'Yıl Seçiniz',
          number2: this.$helper.tutarStrIstatistik(response[endDate]['acilmamis_takip_tahsilat_toplam_tutar']) || '0 ₺',
        },
        {
          id: 3,
          title: 'Derdest Tahsilat Tutarı',
          yil:startDate || 'Yıl Seçiniz',
          number: this.$helper.tutarStrIstatistik(response[startDate]['acik_takip_tahsilat_toplam_tutar']) || '0 ₺',
          yil2: endDate || 'Yıl Seçiniz',
          number2: this.$helper.tutarStrIstatistik(response[endDate]['acik_takip_tahsilat_toplam_tutar']) || '0 ₺',
        },
        {
          id: 4,
          title: 'Hitam Tahsilat Tutarı',
          yil:startDate || 'Yıl Seçiniz',
          number: this.$helper.tutarStrIstatistik(response[startDate]['kapali_takip_tahsilat_toplam_tutar']) || '0 ₺',
          yil2: endDate || 'Yıl Seçiniz',
          number2: this.$helper.tutarStrIstatistik(response[endDate]['kapali_takip_tahsilat_toplam_tutar']) || '0 ₺',
        },
      ]

      if (this.selectedOption === 'Toplam Tahsilat'){


        this.karsilastirmaliGrafikData = [
          {
            name: startDate,
            data: this.$helper.tutarStrIstatistik(response[startDate]['tahsilat_toplam_tutar']) || '0 ₺',
            color: '#5D7285',
          },
          {
            name: endDate,
            data: this.$helper.tutarStrIstatistik(response[endDate]['tahsilat_toplam_tutar']) || '0 ₺',
            color: '#5FB3F6',
          },
        ]
      }


    }catch (error) {
      this.loading = true;

      console.error('hata:', error);
    }
  }


};
